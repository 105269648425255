import global from "./global"

type EnvGetter = (key: string) => string | undefined
type EnvGetterFactoryProps = {
  requiredAtBuildtime?: string[]
  requiredAtRuntime?: string[]
  defaults?: Record<string, string>
}

function Env(props: EnvGetterFactoryProps = {}): EnvGetter {
  const {
    requiredAtBuildtime = [],
    requiredAtRuntime = [],
    defaults = {},
  } = props

  return (key) => {
    // We are on the client
    // @ts-ignore
    if (global != null && global.__ENV__) {
      let value =
        // @ts-ignore
        global.__ENV__[key] != null ? `${global.__ENV__[key]}` : undefined
      if (value == null && defaults[key] != null) {
        value = defaults[key]
      }

      if (value == null && requiredAtRuntime.includes(key)) {
        throw new Error(`Missing env ${key}`)
      }

      return value
    } else {
      // We are on the server
      if (process == null) {
        throw new Error(
          "Error accessing environment, client env not setup properly",
        )
      }

      const serverEnv = process.env
      let value = serverEnv[key]

      if (value == null && defaults[key] != null) {
        value = defaults[key]
      }

      const prerendering = serverEnv.NEXT_PRERENDERING === "1"

      if (prerendering) {
        if (value == null) {
          if (requiredAtBuildtime.includes(key)) {
            throw new Error(`Missing env ${key}`)
          }

          return `RUNTIME_ENV_START_${key}_RUNTIME_ENV_END`
        }
      } else {
        if (value == null && requiredAtRuntime.includes(key)) {
          throw new Error(`Missing env ${key}`)
        }

        return value
      }
    }
  }
}

export const env = Env({
  requiredAtBuildtime: [],
  requiredAtRuntime: [],
  defaults: {
    STORYBLOK_ENV: "published",
  },
})
