import { useEffect } from "react"
import Cookies from "js-cookie"
import posthog from "posthog-js"
import { PostHogProvider as PostHog } from "posthog-js/react"
import { usePathname, useSearchParams } from "next/navigation"
import { env } from "../../config"

declare global {
  interface Window {
    posthog: typeof posthog
    __FLAG_DATA__: any
  }
}

type PostHogWrapperProps = {
  children: React.ReactNode
}

export const PostHogProvider = ({ children }: PostHogWrapperProps) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const posthogKey = env("NEXT_PUBLIC_POSTHOG_KEY")

  const debug = env("FF_DEBUG_POSTHOG") === "1" ? true : false

  if (typeof window !== "undefined" && posthogKey) {
    const flags = Cookies.get("bootstrapData")

    let bootstrapData = {}
    if (flags) {
      bootstrapData = JSON.parse(flags)
    }
    posthog.init(posthogKey, {
      api_host: env("NEXT_PUBLIC_POSTHOG_HOST") || "https://us.i.posthog.com",
      bootstrap: bootstrapData,
      debug,
    })
    if (!window.posthog) {
      window.posthog = posthog
      window.__FLAG_DATA__ = bootstrapData
    }
  }
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`
      }
      posthog.capture("$pageview", {
        $current_url: url,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams, posthog])

  return <PostHog client={posthog}>{children}</PostHog>
}
