import { useEffect } from "react"
import Error from "next/error"
import { ErrorBoundary } from "react-error-boundary"
import type { AppProps } from "next/app"
import Link from "next/link"
import { useRouter } from "next/router"
import {
  Montserrat,
  Roboto,
  Merriweather,
  Inter,
  Work_Sans,
} from "next/font/google"
import store from "store"

import { env } from "../config"
import "../styles/global.css"
import { PostHogProvider } from "../util/posthog/posthogProvider"
import geo from "../services/geoLocation"

const montserrat = Montserrat({
  variable: "--font-montserrat",
  subsets: ["latin"],
})

const roboto = Roboto({
  variable: "--font-roboto",
  weight: ["100", "300", "400", "500", "700", "900"],
  subsets: ["latin"],
})

const inter = Inter({
  variable: "--font-inter",
  subsets: ["latin"],
  display: "swap",
})

const merriweather = Merriweather({
  variable: "--font-merriweather",
  weight: ["300", "400", "700", "900"],
  subsets: ["latin"],
  display: "swap",
})
const work_sans = Work_Sans({
  variable: "--font-work_sans",
  subsets: ["latin"],
  display: "swap",
})
const className = `
  ${montserrat.variable} ${montserrat.className} 
  ${roboto.variable} ${roboto.className} 
  ${merriweather.variable} ${merriweather.className}
  ${inter.variable} ${inter.className}
  ${work_sans.variable} ${work_sans.className}
`.trim()

const appVersion = env("npm_package_version")

const SentryErrorHandler = (() => {
  let initialized = false

  const GetSentry = async () => {
    const SentryModule = await import("@sentry/browser")

    if (!initialized) {
      SentryModule.init({
        release: appVersion,
        // TODO: Make this an env variable
        dsn:
          "https://aca266ea46884a5eb53d88972393b619@o95508.ingest.sentry.io/5281852",
      })
      initialized = true
    }

    return SentryModule
  }

  return async (error: any, errorInfo: any) => {
    const Sentry = await GetSentry()

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })
  }
})()

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}
declare const window: WindowWithDataLayer

if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || []
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter()

  useEffect(() => {
    window.dataLayer.push({
      event: "page_view",
    })
    store.set("last_visited_page", { name: pathname })
  }, [])

  return (
    <>
      <ErrorBoundary
        FallbackComponent={() => <Error statusCode={404} />}
        onError={SentryErrorHandler}
        onReset={() => {
          console.log("error boundary")
        }}
      >
        {pageProps.preview && (
          <header className="z-[1000] fixed bg-black text-white font-bold w-full bottom-0 h-10 text-center flex justify-between items-center px-10 font-mono">
            Preview Mode Enabled
            <Link
              href="/api/exit-preview"
              prefetch={false}
              className="text-white underline"
            >
              Exit Preview
            </Link>
          </header>
        )}
        <main className={className}>
          <PostHogProvider>
            <Component {...pageProps} />
          </PostHogProvider>
        </main>
      </ErrorBoundary>
    </>
  )
}
